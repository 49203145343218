import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import YoutubePlayerLite from "../../components/common/youtube-player-lite"
import { graphql, useStaticQuery } from "gatsby"
import "./press-page-styles.css"
import PageSelection from "../../components/common/PageSelection"
import GridItems from "../../components/common/GridItems"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useWindowSize from "../../utils/useWindowSize"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"

const PressPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("")
  const [gridView, setGridView] = useState(true)
  const [selectedPress, setSelectedPress] = useState()
  const [searchKey, setSearchKey] = useState("")
  const screen = useWindowSize()

  const prismicPressReleases = useStaticQuery(graphql`
    query PressReleasesPageQuery {
      allPrismicBasePage(filter: { uid: { eq: "press" } }) {
        edges {
          node {
            data {
              title
              hero_image {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
      allPrismicPress {
        nodes {
          uid
          tags
          data {
            title
            date
            banner_image {
              alt
              gatsbyImageData(width: 350, placeholder: BLURRED)
            }
          }
        }
        distinct(field: tags)
      }
    }
  `)

  const origPressReleases = useMemo(() => {
    if (prismicPressReleases) {
      const data = prismicPressReleases.allPrismicPress.nodes.map(node => {
        const { uid, data, tags } = node
        const { title, date, banner_image } = data

        return {
          uid,
          title,
          date: new Date(date),
          image: banner_image,
          image_alt: banner_image.alt ?? title,
          tags,
        }
      })

      data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })

      return data
    }

    return []
  }, [prismicPressReleases])

  const categories = useMemo(() => {
    const data = [
      {
        value: "all",
        label: "All",
      },
    ]
    if (prismicPressReleases) {
      for (const tag of prismicPressReleases.allPrismicPress.distinct) {
        data.push({
          value: tag.toLowerCase(),
          label: tag,
        })
      }
    }
    setSelectedCategory(data[0].label)
    return data
  }, [prismicPressReleases])

  useEffect(() => {
    setSelectedPress(origPressReleases)
  }, [])

  useEffect(() => {
    let filtered = [...origPressReleases]
    if (selectedCategory) {
      if (selectedCategory !== "All") {
        filtered = filtered.filter(p => p.tags.includes(selectedCategory))
      }
    }

    if (searchKey) {
      filtered = filtered.filter(press => {
        return press.title.toLowerCase().includes(searchKey.toLowerCase())
      })
    }

    setSelectedPress(filtered)
  }, [selectedCategory, searchKey])

  const image = getImage(
    prismicPressReleases.allPrismicBasePage?.edges[0].node.data.hero_image
  )

  return (
    <Layout>
      <SEOnew
        title={
          prismicPressReleases.allPrismicBasePage?.edges[0].node.data.title
        }
      />
      <div className="w-full relative flex flex-col items-center justify-center">
        <HeroSectionWithTitle
          title={
            prismicPressReleases.allPrismicBasePage?.edges[0].node.data.title
          }
          description={
            "Brion Jeannette Architecture is highly sought after by publications to provide expertise, editorials, knowledge, and experience in the private home experience"
          }
          image={image}
          textLocation="left"
          imagePosition="bottom"
        />
      </div>
      <div className="flex w-full items-center justify-center flex-col pt-2 mb-12 ">
        <div className="mt-12 p-1 text-site-gray flex flex-col items-center">
          <p>
            Brion Jeannette Architecture is featured in numerous regional and
            national publications.
          </p>
          <div className="w-full py-8 max-w-sm sm:max-w-md lg:max-w-lg">
            <YoutubePlayerLite youtubeID="X-avoikEdHQ" />
          </div>
          <p>Below is a sampling of our press.</p>
        </div>
        <div className="w-full max-w-7xl px-4">
          <GridItems pressItems={selectedPress} />
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default PressPage
