import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import useWindowScroll from "../../../utils/useWindowScroll"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TitleDiv = styled.div`
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
`

const DateDiv = styled.div`
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.75);
`

const GridItems = ({ pressItems = [] }) => {
  const winScroll = useWindowScroll()
  const [loadBelowFold, setLoadBelowFold] = useState(false)

  const sortedItems = useMemo(() => {
    let sorted = pressItems.sort((a, b) => b.date - b.date)

    if (!loadBelowFold) {
      sorted = sorted.slice(0, 20)
    }

    return sorted
  }, [pressItems, loadBelowFold])

  useEffect(() => {
    if (winScroll && winScroll.scrollY > 100 && !loadBelowFold) {
      setLoadBelowFold(true)
    }
  }, [winScroll.scrollY])

  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-20 py-2 md:py-12 relative justify-center justify-items-center">
      {sortedItems.map((press, index) => {
        const image = getImage(press.image)

        return (
          <div
            className="w-full max-w-xs h-96 relative flex rounded-md overflow-hidden portfolio-page-item"
            key={`press-${index}-${press.uid}`}
            style={{
              boxShadow: "0px 5px 12px 2px #333333",
              animationDelay: `${0.5 + index * 0.2}s`,
            }}
          >
            <GatsbyImage
              className="w-full h-full"
              alt={press.item_alt}
              image={image}
              loading="lazy"
              objectFit="contain"
            />
            <a
              href={`/press-details/${press.uid}`}
              className="press-page-gridItem"
            >
              <DateDiv className="pb-2 w-full text-center px-2 pt-2">
                {press.date.toLocaleDateString()}
              </DateDiv>
              <TitleDiv className="text-center w-full px-2 pb-2">
                {press.title || <>&nbsp;</>}
              </TitleDiv>
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default GridItems
